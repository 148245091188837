import React, { useState } from "react"
import Footer from "../../components/profissionaisSaude/Footer"
import Header from "../../components/profissionaisSaude/Header"
import Button from "../../components/profissionaisSaude/Inputs/Button"
import { TextInput } from "../../components/profissionaisSaude/Inputs/InputText"
import Image from "../../assets/images/reset-password-picture.svg"
import { navigate } from "gatsby"
import { Container } from "../../components/compartilhados/Container"
import { Main, InfoWrapper, IntroWrapper, InputWrapper, ImageWrapper } from "../../styles/saude/redefinicao-senha"

export default function RedefinicaoSenha() {
  const regexEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/i
  const [error, setError] = useState("")
  const [emailTemp, setEmailTemp] = useState("exemplo@gmail.com")
  const [email, setEmail] = useState("")

  //Esse função irá verificar se o email informado consta na base de usuários
  const searchEmail = () => {
    return email === emailTemp
  }
 
  const sendLink = () => {
    if(searchEmail() && regexEmail.test(email)){
      navigate("/saude/redefinicao-senha-confirmacao", {state: { email }})
    }else{
      setError("Não conseguimos localizar seu e-mail em nosso cadastro. Por favor, tente novamente ou crie uma conta.")
    }
  }

  const validate = (e) => {
    if(!regexEmail.test(e.target.value)){
      setError("Por favor, utilize um formato de e-mail válido. Por exemplo: email@dominio.com.br")
    }else{
      setError("")
      setEmail(e.target.value)
    }

    if(e.target.value === '')
      setError("")
  }

  return (
    <Container>
      <Header />
      <Main>
        <InfoWrapper>
          <IntroWrapper>
            <h1>Redefinir senha</h1>
            <p>Vamos enviar um link em seu e-mail para redefinir a sua senha.</p>
          </IntroWrapper>
          <InputWrapper>
            <TextInput 
              label="E-mail"
              placeholder="Digite seu e-mail"
              error={error}
              onChange={(e) => {validate(e)}}
              large={false}
            />
            <Button
              title="Enviar link"
              noLink
              onClick={sendLink}
            />
          </InputWrapper>
        </InfoWrapper>
        <ImageWrapper>
          <Image className="main-image" />
        </ImageWrapper>
      </Main>
      <Footer />
    </Container>
  )
}
