import styled from "styled-components"

export const Main = styled.div`
    margin: 3.0438rem 0 4.0431rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 7.625rem;
    min-height: calc(100vh - 20.3369rem);

    @media screen and (max-width: 1020px) {
        padding: 1rem 2.5rem;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        gap: ${({ theme }) => theme.spacing.m};
    }
`
export const InfoWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.l};
  max-width: 28.625rem;
  width: 100%;
`

export const IntroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.s};
  color: ${({ theme }) => theme.colors.gray.text_base};

  h1 {
    font-size: ${({ theme }) => theme.fontSize.fontSize.fontSizeXL};
    font-weight: ${({ theme }) => theme.fontWeight.fontWeight.fontWeightBold};
    line-height: ${({ theme }) => theme.lineHeight.lineHeight.lineHeightMedium};
  }

  p {
    font-size: ${({ theme }) => theme.fontSize.fontSize.fontSizeBase};
    font-weight: ${({ theme }) => theme.fontWeight.fontWeight.fontWeightNormal};
    line-height: ${({ theme }) => theme.lineHeight.lineHeight.lineHeightNormal};
  }
`

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.l};
`

export const ImageWrapper = styled.section`
  max-width: 33.9375rem;
  max-height: 25.9131rem;

  .main-image {
    width: 100%;
    height: 100%;
  }
`